import React from "react";
import styles from "./styles.module.css";
import Button from "../../../../components/Button";
import oscommerce from "../../../../../../assets/img/oscommerce.jpg";

export default function OSCommerce() {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <h1>Panier d'achats virtuel évolué osCommerce</h1>
        <img
          src={oscommerce}
          alt="Deux caddies"
          style={{ float: "right" }}
        ></img>
        <p>
          osCommerce est une solution en ligne évoluée qui offre un large
          éventail de fonctions prêt à l'emploi pour le cybercommerce. C'est un
          logiciel libre, donc offert gratuitement, car diffusé sous licence GPL
          (General Public License). Lancé en mars 2000, osCommerce n'a cessé
          depuis de se développer pour devenir cette solution qui fait
          fonctionner actuellement par le monde plus de 12 000 boutiques
          dynamiques et dûment enregistrées.
        </p>

        <p>
          Aujourd’hui, osCommerce est amené à un niveau encore plus élevé : non
          seulement est-il un cadre qui permet toujours aussi aisément de
          construire et d’opérer un cybercommerce, il rend maintenant plus
          facile aux administrateurs de boutiques virtuelles de présenter
          celles-ci à leurs clients selon leurs propres exigences.
        </p>
        <p>
          Le succès d’osCommerce repose sur une large et active communauté dont
          les membres s’entraident et prennent part aux différents aspects du
          développement du système, ce que reflète l’état actuel du projet.
        </p>
        <Button
          text={"Comparer les forfaits"}
          url={
            "/developpez/services-web/solutions-pour-le-cybercommerce/forfaits-cybercommerce/"
          }
        />
      </div>
      <div className={styles.row}>
        <h2>Pourquoi choisir osCommerce?</h2>
        <div className={styles.column}>
          <h3>Caractéristiques</h3>
          <ul className={styles.bullet}>
            <li>
              Modèles de boutiques osCommerce de conception professionnelle
            </li>
            <li>Assistant d'installation convivial</li>
            <li>Configuration automatisée d'une base de données MySQL</li>
          </ul>
        </div>
        <div className={styles.column}>
          <h3>Avantages</h3>
          <ul className={styles.bullet}>
            <li>
              Ensemble de modèles de vitrines virtuelles de niveau
              professionnel, conçus pour être utilisés avec osCommerce - gratuit
              avec tous nos forfaits optimisés pour le cybercommerce
            </li>
            <li>
              Vous guide tout au long de la procédure d'installation
              d'osCommerce
            </li>
            <li>
              Configure automatiquement votre base de données MySQL : accélère
              et simplifie ce processus qui s'avère souvent compliqué
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
