import React from "react";
import Layout from "../../../../../other/layout";
import OSCommerce from "../../../../../components/AC-StaticPages/Developpez/ServicesWeb/SolutionsPourLeCybercommerce/OSCommerce/OSCommerce";
import SEO from "../../../../../other/seo";

export default function osCommerce() {
  return (
    <Layout>
      <SEO title="Panier d'achats virtuel évolué osCommerce" />
      <OSCommerce />
    </Layout>
  );
}
